import styled from 'styled-components'

export const H3TitleContainer=styled.h3`
    width:1125px;
    height:32px;
    margin:0 auto;
    display:block;
    padding:5px 0;
    
`





