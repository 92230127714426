import React from 'react'

function TakeAway桃園() {
    return (
        <div style={TakeContainer}>
        <p style={{backgroundColor:"#00ffff",width:"100%",fontSize:"13px"}}>Please click on the branch name to enter the order, thank you !</p>
        <table className="takeawayTable">
            <thead>
                <tr>
                    <th style={{textAlign:"left"}}>Stores</th>
                    <th style={{textAlign:"left"}}>Information</th>
                    <th style={{textAlign:"left"}}>Address</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd",borderRight:"1px solid #ddd"}}><a href="#!">Hsinchu Guanxin Branch</a></td>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd",borderRight:"1px solid #ddd"}}>03-5630189</td>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd"}}>No. 175, Guanxin Rd, East Dist, Hsinchu City</td>
                </tr>
                <tr>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd",borderRight:"1px solid #ddd"}}><a href="#!">Hsinchu Guanxin Branch</a></td>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd",borderRight:"1px solid #ddd"}}>03-5630189</td>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd"}}>No. 175, Guanxin Rd, East Dist, Hsinchu City</td>
                </tr>
                <tr>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd",borderRight:"1px solid #ddd"}}><a href="#!">Hsinchu Guanxin Branch</a></td>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd",borderRight:"1px solid #ddd"}}>03-5630189</td>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd"}}>No. 175, Guanxin Rd, East Dist, Hsinchu City</td>
                </tr>
                <tr>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd",borderRight:"1px solid #ddd"}}><a href="#!">Hsinchu Guanxin Branch</a></td>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd",borderRight:"1px solid #ddd"}}>03-5630189</td>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd"}}>No. 175, Guanxin Rd, East Dist, Hsinchu City</td>
                </tr>
                <tr>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd",borderRight:"1px solid #ddd"}}><a href="#!">Hsinchu Guanxin Branch</a></td>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd",borderRight:"1px solid #ddd"}}>03-5630189</td>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd"}}>No. 175, Guanxin Rd, East Dist, Hsinchu City</td>
                </tr>
                <tr>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd",borderRight:"1px solid #ddd"}}><a href="#!">Hsinchu Guanxin Branch</a></td>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd",borderRight:"1px solid #ddd"}}>03-5630189</td>
                    <td style={{padding:"8px",borderTop:"1px solid #ddd"}}>No. 175, Guanxin Rd, East Dist, Hsinchu City</td>
                </tr>
            </tbody>
        </table>
        </div>
      )
    
}

const TakeContainer={
    width:"620px",
    margin:"0 auto",
    fontSize:"20px"
}


export default TakeAway桃園
