import styled from 'styled-components'

export const VipContainer=styled.div`
    width:1800px;
    height:auto;
    margin:0 auto;
`
export const FormContainer=styled.div`
    width:1125px;
    height:auto;
    fnot-size:36px;
    display:block;
    line-height: 1.1;
    box-sizing: border-box;
    position:relative;
    left:5%;
`
