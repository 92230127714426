import React from 'react'

function LoginMember() {
  return (
    <div>
      kkkkkkkkkkkk
    </div>
  )
}

export default LoginMember
