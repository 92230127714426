import styled from "styled-components";

export const FirstLogInMainTitle=styled.h1`
    display:block;
    height:auto;
    text-align:left
    fnot-size:36px;
    font-weight:400;
    margin-bottom:1.5rem;
    width:1125px;
`
export const FirstLogInItems=styled.section`
    height:auto;
    width:1125px;
    display:inline-block;
`