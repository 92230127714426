import styled from 'styled-components'

export const OnlineContainer=styled.div`
    width:730px;
    margin-left:300px;
    box-sizing: border-box;
`
export const OnlineFromContainer=styled.div`
    width:730px;
    box-sizing: border-box;
    border:2px solid #eee;
    border-radius:8px;
`
